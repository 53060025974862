import React, { useState } from 'react';
import styled from 'styled-components';
import Header from '../components/Header';
import axios from 'axios';

const ContactSection = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  min-height: 100vh;
  background: linear-gradient(180deg, #f8fafc, #e2e8f0);
  padding: 2rem 0;
  color: #1e293b;
  overflow: hidden;
`;

const Header2 = styled.div`
  width: 100%;
  padding: 1rem;
  background: ${({ theme }) => theme.colors.primary};
  text-align: center;
  border-radius: 8px;
  margin-bottom: 1.5rem;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);

  h1 {
    font-size: 1.2rem;
    color: white;
    margin: 0;
  }
  p {
    color: #f8fafc;
    font-size: 1rem;
    margin: 0.5rem 0 0;
  }
`;
const InfoContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 90%;
  max-width: 1000px;
  margin: 1.5rem auto;
  gap: 1.5rem;
  @media (max-width: 768px) {
    flex-direction: column;
    gap: 1rem;
  }
`;

const InfoCard = styled.div`
  background: #ffffff;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 1.5rem;
  flex: 1;
  text-align: center;
  min-width: 260px;
  height: 160px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #475569;
  font-weight: normal;
  a {
    color: #2563eb;
    text-decoration: underline;
  }
`;
const FormContainer = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 90%;
  max-width: 400px;
  background: white;
  padding: 2rem;
  border-radius: 12px;
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.1);
  margin-top: 1.5rem;
  text-align: center;
`;

const InputWrapper = styled.div`
  display: flex;
  align-items: center;
  border: 2px solid #60a5fa;
  border-radius: 8px;
  background: #f8fafc;
  padding: 0.2rem;
  font-size: 1rem;
  gap: 2px;
  margin-top: 1rem;
  width: 100%;
  max-width: 350px;
`;

const CountryCodeInput = styled.input`
  width: 50px;
  border: none;
  outline: none;
  font-size: 1rem;
  font-weight: bold;
  background: transparent;
  color: #1e293b;
  text-align: center;
  &::placeholder {
    color: rgba(148, 163, 184, 0.5);
  }
`;

const PhoneNumberInput = styled.input`
  flex: 1;
  border: none;
  outline: none;
  font-size: 1rem;
  font-weight: bold;
  background: transparent;
  color: #1e293b;
  text-align: left;
  &::placeholder {
    color: rgba(148, 163, 184, 0.5);
    letter-spacing: 1px;
  }
`;

const SubmitButton = styled.button`
  background: linear-gradient(135deg, #3b82f6, #60a5fa);
  color: white;
  border: none;
  border-radius: 10px;
  padding: 0.8rem 1.5rem;
  font-size: 1rem;
  cursor: pointer;
  transition: all 0.3s;
  width: 100%;
  margin-top: 1rem;
  font-weight: bold;
  &:hover {
    transform: translateY(-2px);
    opacity: 0.9;
  }
`;

const Contact = () => {
  const [countryCode, setCountryCode] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [submissionStatus, setSubmissionStatus] = useState(null);

  const handlePhoneNumberChange = (e) => {
    let cleaned = e.target.value.replace(/\D/g, '').slice(0, 10);
    let formattedNumber = cleaned.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3');
    setPhoneNumber(formattedNumber);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const headers = {};
    headers['Content-Type'] = 'application/json';
    axios.defaults.withCredentials = true;
    try {
        await axios.post('https://support.kudiltech.com/api/v1/sms/consent', {
        countryCode: parseInt(countryCode, 10) || 1,
        phoneNumber: phoneNumber.replace(/\D/g, ''),
        consentGiven: true,
        message: 'By entering my phone number, I agree to receive one-time passcode or one-time URL link to chat with Support AI Agent. Message frequency varies per usage. Message and data rates apply. Reply STOP to unsubscribe.',
      });
      setSubmissionStatus('success');
    } catch (error) {
      console.error('Error submitting consent:', error);
      setSubmissionStatus('failure');
    }
  };

  return (
    <ContactSection>
    <Header />
    <Header2>
      <h1>Contact Us</h1>
      <p>Get in touch with our team!</p>
    </Header2>
    <InfoContainer>
      <InfoCard>
        <h2>Our Address</h2>
        <p>8718 Club Estates Way</p>
        <p> Lake Worth, FL 33467</p>
        <p>Email: <a href="mailto:support@kudiltech.com">support@kudiltech.com</a></p>
      </InfoCard>
      <InfoCard>
        <h2>Chat with Us</h2>
        <p><a href="/chat">Click here to chat</a></p>
      </InfoCard>
      <InfoCard>
        <h2>Call Us</h2>
        <p>+1 504 356 7626</p>
      </InfoCard>
    </InfoContainer>
      <FormContainer onSubmit={handleSubmit}>
        {submissionStatus === 'success' ? (
          <p>Consent submitted successfully!</p>
        ) : submissionStatus === 'failure' ? (
          <div>
            <p>Consent submission failed. Please try again.</p>
            <SubmitButton onClick={() => setSubmissionStatus(null)}>Try Again</SubmitButton>
          </div>
        ) : (
          <>
            <p>SMS Consent</p>
            <InputWrapper>
              <span>+</span>
              <CountryCodeInput type="text" placeholder="1" value={countryCode} maxLength="4" onChange={(e) => setCountryCode(e.target.value.replace(/[^0-9]/g, ''))} />
              <PhoneNumberInput type="text" placeholder="123-456-7890" value={phoneNumber} onChange={handlePhoneNumberChange} />
            </InputWrapper>
            <p style={{ marginTop: '1rem', fontSize: '0.9rem', color: '#475569' }}>By entering my phone number, I agree to receive one-time passcode or one-time URL link to chat with Support AI Agent. Message frequency varies per usage. Message and data rates apply. Reply STOP to unsubscribe.</p>
            <SubmitButton type="submit">Submit</SubmitButton>
          </>
        )}
      </FormContainer>
    </ContactSection>
  );
};

export default Contact;
